import React from "react";
import { Link } from "gatsby";
import {
  edgeTest,
  decodeEntities,
  trailingSlash,
  isBrowser,
} from "../../utils/helpers";

export const scrollIntoView = (event, containerId) => {
  if (typeof window !== "undefined") {
    event.preventDefault();
    containerId = containerId.replace("#", "");
    const results = document.getElementById(containerId)
      ? document.getElementById(containerId)
      : null;
    results && results.scrollIntoView({ block: "start", behavior: "smooth" });
  }
};

const GatsbyLink = (props) => {
  const { to, children, decode = false, className = null, ...other } = props;
  const newTo = to
    .replace(process.env.GATSBY_APP_ADDRESS, "")
    .replace("/./", "/"); //Remove blog category base
  const internal = /^\/(?!\/)/.test(newTo);
  const isActive = () => {
    const currentLocation = isBrowser && window.location;
    if (to.includes(currentLocation)) {
      return className + " active";
    }
    return className;
  };

  // if ( to && to.includes('#') ) {
  //   return <span onClick={event => this.scrollIntoView(event, to)} className={className} {...other}>{decode ? decodeEntities(children) : children}</span>
  // }

  if (internal && edgeTest()) {
    return (
      <Link
        data-internal="true"
        className={isActive()}
        {...other}
        to={trailingSlash(newTo)}
      >
        {decode ? decodeEntities(children) : children}
      </Link>
    );
  }

  return (
    <a href={to} data-internal="false" className={className} {...other}>
      {decode ? decodeEntities(children) : children}
    </a>
  );
};

export default GatsbyLink;
